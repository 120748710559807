// import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes ,Route, HashRouter } from 'react-router-dom';

// Assuming you have components for Login and Dashboard
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import ProductTable from './pages/product-details';
import Scan from './pages/scan';

function App() {
  // Simulate authentication status. You can replace this with your actual authentication logic.


  return (
    <HashRouter>
    {/* <Layout> */}
      <Routes>
        <Route exact path="/" element={<Login/>}/>
        <Route exact path="/login" element={<Login/>}/>
        <Route exact path="/dashboard" element={<Dashboard/>}/>
        <Route exact path="/store/:id" element={<ProductTable/>}/>
        <Route exact path="/scan/:id" element={<Scan/>}/>

        {/* <Route path="*" element={<NotFound/>}/> */}
      </Routes>
    {/* </Layout> */}
  </HashRouter>
  );
}

export default App;
