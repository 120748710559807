import React from "react";
import { NavLink } from "react-router-dom";
// import { useState } from "react";
const imageSrc = require('../logo.jpeg');
const logout_logo = require('../download (1).png')

const Header = () => {
    async function checkValidLogin() {
        let token = localStorage.getItem("amira-token");
        if (!token) {
            window.location.href = '/';
        }
    }

    async function logout() {
        localStorage.removeItem("amira-token");
        window.location.href = '/';
    }
    checkValidLogin();
    return (
        <nav className="navbar navbar-expand-lg navbar-dark mx-5">
            <div className="container-fluid d-flex justify-content-space-between">
                {/* <a className="navbar-brand mt-2 mt-lg-0" href="#"> */}
                    {/* <h5 className="pt-1">MDB</h5> */}
                    <NavLink className="navlink navbar-brand mt-2 mt-lg-0" to={"/dashboard/"}>

                        <img src={imageSrc} alt='logo' />
                    </NavLink>
                {/* </a> */}

            </div>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <div className="logout-box">
                    <a className="button rounded-pill" >
                        <img src={logout_logo} />

                        <div className="logout" onClick={logout}>LOGOUT</div>

                    </a>
                </div>
            </div>
            {/* </div> */}
        </nav>
    );
};

export default Header;
